@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=KoHo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

:root {
  --doc-height: 100%;
 }

body {
  padding: 0;
  margin: 0;
  height: 100vh; /* fallback for js load */
  height: var(--doc-height);
  font-family: "KoHo", -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Taken from https://codepen.io/diogopalhais/pen/Zrojxd */

.wrapper {
  width:100%;
  padding:5px 10px;
  background-color:#17191F;
  border-radius:5px;
}

.campo {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 0;
  margin: 10px auto;
  padding: 0 0 60% 0;
}

.interior, .divisoria,
.semi1, .semi2,
.corner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  border: 1px solid #323642;
}

.divisoria:after, .divisoria:before {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  width: 0;
  height: 100%;
  margin-left: .5px;
  border: 0.5px solid #323642;
}
.divisoria:before {
  top: 50%;
  width: 17%;
  height: 0;
  margin: -8% 0 0 -8%;
  margin-top: calc(-8% - 4px);
  margin-left: calc(-8% - .5px);
  padding-bottom: 17%;
  border-radius: 50%;
  border: 1px solid #323642;
}

.campo:before, .campo:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 1px;
  margin: -8% 0 0 -1px;
  display: block;
  width: 8%;
  height: 30%;
  border: 1px solid #323642;
  z-index: 2;
}

.campo:after {
  left: auto;
  right: -2px;
}

.interior:before, .interior:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  margin: -16% 0 0 -1px;
  display: block;
  width: 16%;
  height: 55%;
  /*background: rgb(27,31,38);*/
  background-color: #17191F;
  border: 1px solid #323642;
}

.interior:after {
  left: auto;
  right: 0;
  margin: -16% -1px 0 0;
}

.semi2:before {
  left: auto;
  right: 12%;
}

.semi1:after, .semi2:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 5%;
  width: 16%;
  height: 0;
  margin: -8% 0 0 0;
  padding-bottom: 16%;
  border: 1px solid #323642;
  border-radius: 50%;
}
.semi2:after {
  left: auto;
  right: 5%;
}

.gk{
  content: "";
  position: absolute;
  top: 48%;
  left: 2%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.lb{
  content: "";
  position: absolute;
  top: 10%;
  left: 14%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.cb{
  content: "";
  position: absolute;
  top: 48%;
  left: 14%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.rb{
  content: "";
  position: absolute;
  top: 85%;
  left: 14%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.lwb{
  content: "";
  position: absolute;
  top: 10%;
  left: 30%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.dm{
  content: "";
  position: absolute;
  top: 48%;
  left: 30%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.rwb{
  content: "";
  position: absolute;
  top: 85%;
  left: 30%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.lm{
  content: "";
  position: absolute;
  top: 10%;
  left: 49%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.cm{
  content: "";
  position: absolute;
  top: 48%;
  left: 49%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.rm{
  content: "";
  position: absolute;
  top: 85%;
  left: 49%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.amr{
  content: "";
  position: absolute;
  top: 10%;
  left: 67%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.am{
  content: "";
  position: absolute;
  top: 48%;
  left: 67%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.aml{
  content: "";
  position: absolute;
  top: 85%;
  left: 67%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.wl{
  content: "";
  position: absolute;
  top: 10%;
  left: 83%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.cf{
  content: "";
  position: absolute;
  top: 48%;
  left: 83%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.wr{
  content: "";
  position: absolute;
  top: 85%;
  left: 83%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}

.st{
  content: "";
  position: absolute;
  top: 48%;
  left: 95%;
  display: block;
  width: 3%;
  height: 5%;
  /* background-color: #4F7EDC; */
  border: 1px solid #324978;
  border-radius: 50%;
  z-index: 2;
}